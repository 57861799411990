// TextField.tsx
import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import Asterix from './Asterix';
import { ErrorText } from './ErrorText';
import { Label } from './Label';

const CheckboxContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  width: 100%;

  @media (min-width: 768px) {
    padding-top: calc((0.15625vw + 13.375px) * 1.2 + 12px);
  }

  @media (min-width: 1680px) {
    padding-top: calc(16px * 1.2 + 12px);
  }

  label {
    position: relative;

    display: flex;
    align-items: center;

    height: calc(13.3333px * 1.2 + 24px);

    margin-bottom: 0;
    padding-left: calc(13.3333px * 1.2 + 24px + 12px);

    @media (min-width: 1680px) {
      height: calc(16px * 1.2 + 24px);
      padding-left: calc(16px * 1.2 + 24px + 12px);
    }
  }
`;

const Input = styled.input<{ isError?: boolean }>`
  position: absolute;

  top: 0;
  left: 0;

  margin: 0;

  width: calc(13.3333px * 1.2 + 24px);
  height: calc(13.3333px * 1.2 + 24px);

  @media only screen {
    border-width: 2px;
  }

  @media (min-width: 1680px) {
    width: calc(16px * 1.2 + 24px);
    height: calc(16px * 1.2 + 24px);
  }

  ${(props) =>
    props.isError &&
    css`
      border-color: rgba(236, 89, 71, 0.6);
      border-width: 2px;
    `}

  &:focus {
    outline: none;
  }

  &:after {
    content: '';
    position: absolute;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background: white;
    border: 2px solid #c6d5e6;
    color: #00478e;
    font-size: 24px;

    pointer-events: none;
    transition: border-color 0.3s;
  }

  &:checked:after {
    content: '✔';
  }

  &:focus:after {
    border-color: #00478e;
  }
`;

interface Props {
  label: ReactNode;
  name: string;
  value: boolean;
  onChange: (input: boolean) => void;
  required: boolean;
  error: string;
}

const CheckBox: React.FC<Props> = ({
  label,
  name,
  value,
  onChange,
  required,
  error,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <CheckboxContainer>
      <Label htmlFor={name}>
        <span>
          {label} {required ? <Asterix /> : ''}
        </span>
        <Input
          type='checkbox'
          id={name}
          name={name}
          checked={value}
          onChange={handleChange}
          isError={error !== '' && value === false}
        />
      </Label>

      {error !== '' && value === false && <ErrorText>{error}</ErrorText>}
    </CheckboxContainer>
  );
};

export default CheckBox;
